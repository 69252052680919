// first section styling++++++++++***********************
// @import url('https://fonts.googleapis.com/css2?family=Sofia&family=Sofia+Sans+Condensed:wght@500;700;800;900&display=swap');
@font-face {
    font-family: sofiaPro;
    src: url('../Sofia.Pro.Condensed.NET/TTF/SofiaPro-Condensed41.ttf');
}

@font-face {
    font-family: sofiaProBold;
    src: url('../Sofia.Pro.Condensed.NET/TTF/Sofia\ Pro\ Bold\ Az.otf');
}

@font-face {
    font-family: sofiaProCondensed;
    src: url('../Sofia.Pro.Condensed.NET/TTF/SofiaPro-Condensed31.ttf');
}

// @font-face {
//     font-family: sofia2;
//     src: url('../../Sofia.Pro.Condensed.NET/TTF/SofiaPro-Condensed41.ttf');

// }

.special_font {
    font-family: sofiaPro !important;
    font-size: 1.5rem !important;
    font-weight: 800 !important;
    letter-spacing: 1px !important;
}

.sofia_pro_font {
    font-family: sofiaProCondensed !important;
    letter-spacing: 2px;
}


// change only home page font size
.very_big_font {
    font: bold 3.3rem/3.7rem Source Sans Pro;
}

.title_font {
    font: bold 2.3rem/2.7rem Source Sans Pro;
}

.subtitle_font {
    font: 400 1.2rem Source Sans Pro;
}

.medium_font {
    font: 600 2rem Source Sans Pro;
}

.trial_font {
    font: 600 1rem Source Sans Pro;
    color: #000;
}

.new_width {
    width: 50%;
}

.status_div {
    padding: 0 2rem;
}

// change only home page font size

.display_block {
    display: none;
}

.statmockup_div {
    // border: 1px solid red;
    width: 50%;
}

.statmockup_image {
    width: 100%;
}

.comon_btn {
    background: #1F1F1F;
}

.comon_btn:hover {
    background: #1f1f1fde;
}

.comon_btn2 {
    background: #1F1F1F;
    display: flex;
    align-items: center;
    justify-content: center;
}

.comon_btn2:focus {
    background: #c0c0c07e;
}

.comon_btn2:hover {
    background: #c0c0c043;
}

.back_design {
    background: #1F1F1F;
    color: #fff;
    padding: 3rem 2rem;
}

.icon_div {
    border: 1px solid #fff;
    padding: 0.5rem 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
}

.icon_div>img {
    width: 100%;
}

.main_background {
    // background: url('../../assets/backpanel2.png') no-repeat center center;
    background: linear-gradient(to top, #0d0d0de9, #0d0d0d40 80%), url('https://d1uxchv5kl1noj.cloudfront.net/bulletimage1.jpeg') no-repeat center;
    height: 100%;
    width: 100%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    position: relative;
    top: 0%;
}

.content_div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 120vh;
    text-align: center;
    background-image: linear-gradient(to top, #0d0d0db7, #0d0d0d1e);

    .text_content {
        h2 {
            font: bold 3.5rem/4rem Source Sans Pro;
            font-family: sofiaPro;
            color: #fff;
            letter-spacing: 2px;

            span {
                color: #AE841E;
                font-family: sofiaPro;
            }
        }

        h3 {
            font: 600 2.5rem/4rem Sofia Pro Condensed;
            font-family: sofiaPro;
            color: #fff;

            span {
                color: #AE841E;
                font-family: sofiaPro;
            }
        }

        .normal_font {
            font: 400 1rem/1.5rem Source Sans Pro;
            color: #fff;
        }

        .store_btn {
            img {
                width: 30%;
            }
        }
    }
}

.width_ {
    width: 50%;
}

.store_links_first>a>img {
    width: 10%;
    margin: 0 0.4rem;
}

.store_links>a>img {
    width: 23%;
    margin: 0 0.4rem;
}

.price_store_links>a>img {
    width: 35%;
    margin: 0 0.4rem;
}


// Second section styling====================***********************
.trusted_text {
    font: bold 2rem/4rem Source Sans Pro;
    color: #1F1F1F;
}

.trusted_text_subtitle {
    font: 400 1rem/1.5rem Source Sans Pro !important;
    color: #1F1F1F;
}

.star_rating_content {
    display: flex;
    justify-content: space-around;
}

.quote_img {
    width: 15%;
    position: relative;
}

.slick-slide>div {
    padding: 0 1rem;
}

.main_slider {
    width: 80%;
    margin: auto;
    margin-top: -3rem;
}

.slider_card {
    box-shadow: 0px 10px 15px #8080801A;
    border-radius: 10px;
}

.card_desc {
    text-align: left;
    height: 220px;
    // overflow: auto;
    padding: 0 0.8rem;
}

.card_desc_text {
    color: #222222;
}

.name_content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.slick-next {
    margin-right: -25px !important;
}

.slick-prev {
    margin-left: -25px !important;
}

.slick-prev:before,
.slick-next:before {
    font-family: 'slick' !important;
    opacity: 1 !important;
    color: #1F1F1F !important;
    font-size: 30px !important;
    font-weight: bold;
}

.slick-dots li button:before {
    margin: 1rem 0;
    font-size: 0.7rem !important;
}

.slick-dots li.slick-active button:before {
    // color: #AE841E !important;
    color: #1F1F1F !important;
}

//   third section stylying*********************+++++++++++++++++++++==
.third_section {
    position: relative;
}

.bullet_image1 {
    width: 100%;
    height: 80vh;
}

.third_div {
    background: linear-gradient(to bottom, #00000091, #1a1919c9), url('../assets/banner.jpg') center no-repeat;
    background-size: cover;
    padding: 8rem 3rem;
    // height: 50vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    // flex-direction: column;
}

.eight_div {
    background: linear-gradient(to bottom, #00000091, #1a1919c9), url('../assets/bulletimage3.png') center no-repeat;
    background-size: cover;
}

.free_trail {
    h3 {
        font: 600 2.5rem/4rem Sofia Pro Condensed;
        font-family: sofiaPro;
        color: #fff;

        span {
            color: #AE841E;
            font-family: sofiaPro;
        }
    }
}

.fifth_div {
    background: linear-gradient(to bottom, #00000091, #1a1919ea), url('https://d1uxchv5kl1noj.cloudfront.net/bulletimage2.png') center no-repeat;
    background-size: cover;
}

.big_font {
    font: bold 1.4rem Source Sans Pro;
    color: #1F1F1F;
}

.normal_font1 {
    font: 500 1rem/1.5rem Source Sans Pro;
    color: #fff;
    // margin: 0.7rem 0;
    letter-spacing: 1px;
}

.margin_text {
    margin: 3rem 0;
}

// fourth section styling=====***********+++++++++++++++----------------
.bold_font {
    font: bold 1rem Source Sans Pro;
    color: #1F1F1F;
}

.normal_font {
    font: 400 1rem Source Sans Pro;
    color: #1F1F1F;
}

.trophy_content_div {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.trophy_content_div2 {
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.img_div {
    //  border: 1px solid red; 
    //  height: 100%;
}

.img_div>img {
    width: 100%;
    height: 100%;
}

.trophy_div {
    margin-bottom: 1rem;
}

.trophy_div>img {
    width: 12%;
}


// sixth section styling****************************+++++++++++++++++++=
.prices_list {
    background: #ffffffdf;
    box-shadow: 0px 0px 30px #a6a6a633;
    padding: 1.5rem 2.2rem;
    border-radius: 10px;
}

.progress_div {
    background: #1F1F1F;
    height: 100%;
}

.ant-steps-vertical>.ant-steps-item>.ant-steps-item-container>.ant-steps-item-tail:after {
    height: 200%;
    margin-top: -0.6rem;
}

.ant-steps-item-process .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
    background: #FFF;
}

.ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
    background: #fff;
}

.ant-steps-item-wait .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
    background: #fff;
}

.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail:after {
    width: 2px !important;
    background-color: #ffffff;
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-tail:after {
    width: 2px !important;
    background-color: #f0f0f030;
}

.ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-tail:after {
    width: 2px !important;
    background-color: #f0f0f030;
}

.ant-steps-item-title {
    font: 500 1.2rem Source Sans Pro;
    color: #fff !important;
    font-style: italic;
}

.pricing_div {
    background: linear-gradient(to bottom, #0000003a, #1a1919ea), url('https://d1uxchv5kl1noj.cloudfront.net/bulletimage4.png') center no-repeat;
    padding: 6rem 2rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
}

.price_card {
    box-shadow: 0px 10px 40px #8080801A;
    // border-radius: 5px;
}

.card_details {
    margin: 1rem 0;
    background-image: url("https://d1uxchv5kl1noj.cloudfront.net/fist.png"), linear-gradient(#ffffffe8, #ffffffee);
    background-repeat: no-repeat;
    background-position: center;
    padding: 0.5rem;
    box-shadow: 0px 10px 40px #8080801A;
    border-radius: 5px;
    background-size: contain;
    border: 1px solid lightgray;
}

.card_details>.ant-typography {
    padding: 0.3rem 0;
    color: #1F1F1F;
    font-weight: 600;
}

// seventh section styling==**********==++++++++++++++++++++++++++
.accordion-button {
    background-color: #FFFCEE;
    border: 1px solid #F0E4B2;
    border-radius: 0;
    overflow-anchor: none;
    // outline: none !important;
    box-shadow: none !important;
}

.accordion-button:focus {
    border: 1px solid #F0E4B2;
    outline: 0;

}

.accordion-button:not(.collapsed) {
    box-shadow: none !important;
    color: #222;
    background-color: #FFFCEE;
}

.accordion-item {
    border: none !important;
}

.accordion-body {
    border: 1px solid #F0E4B2;
}

.accordion-button::after {
    background-image: url("../assets/plus.png");
}

.accordion-button:not(.collapsed)::after {
    background-image: url("../assets/plus.png");
}

.small_font {
    font: bold 1.1rem/1.7rem Source Sans Pro;
    letter-spacing: 0.5px;
}

.very_small_font {
    font: 500 1rem/1.5rem Source Sans Pro;
}


// css responsiveness===========************+++++++++++
@media screen and (min-width:1700px) {
    .content_div {
        .text_content {
            h2 {
                font: 700 5rem/7rem Sofia Pro Condensed;
                font-family: sofiaPro;
            }

            .normal_font {
                font: 400 1.5rem/2rem Source Sans Pro;
            }

            .store_btn {
                img {
                    width: 35%;
                }
            }
        }
    }

    .trusted_text {
        font: bold 3rem Source Sans Pro;
    }

    .trusted_text_subtitle {
        font: 400 1.3rem/2rem Source Sans Pro !important;
    }

    .main_slider {
        margin-top: -5rem;
    }

    .slider_card {
        height: 400px;
    }

    .bullet_image1 {
        height: 100%;
    }

    .big_font {
        font: bold 2rem Source Sans Pro;
    }

    .normal_font1 {
        font: 500 1.5rem/2.5rem Source Sans Pro;
        // margin: 1rem 0;
    }

    .link_tags a {
        padding: 1rem 0;
    }

    .small_font {
        font: bold 1.5rem Source Sans Pro;
    }

    .very_small_font {
        font: 300 1.3rem Source Sans Pro;
    }

    .ant-steps-item-title {
        font: 500 1.5rem Source Sans Pro;
        font-style: italic;
    }

    .card_details>.ant-typography {
        font: 500 1.5rem Source Sans Pro;
    }

    .normal_font {
        font: 500 1.3rem Source Sans Pro;
    }

    .subtitle_font {
        font: 400 1.3rem Source Sans Pro;
    }

    .bold_font {
        font: bold 1.5rem Source Sans Pro;
        ;
    }
}

@media screen and (max-width:1080px) and (min-width:992px) {
    .third_div {
        padding: 6rem 3rem;
    }

    .trophy_content_div2 {
        height: 50%;
    }

    .store_links>a>img {
        width: 35%;
    }

    .price_store_links>a>img {
        width: 40%;
        margin: 0 0.4rem;
    }
}

@media screen and (max-width:1024px) {
    .store_links>a>img {
        width: 35%;
    }

    .store_links_first>a>img {
        width: 15%;
        margin: 0 0.5rem;
    }
}

@media screen and (max-width:850px) {
    .new_width {
        // width: 100%;
    }

    .title_font {
        font: bold 2rem Source Sans Pro;
    }

    .content_div {
        height: 100vh;
    }

    .trophy_div>img {
        width: 20%;
    }

    .store_links>a>img {
        width: 45%;
    }
}

@media screen and (max-width:767px) {
    .content_div {
        .text_content {
            h2 {
                font: 900 2rem/3rem sofiapro;
                letter-spacing: 1px;
            }

            p {
                font: 500 1rem/1.5rem Source Sans Pro;
            }

            .store_btn {
                img {
                    width: 20%;
                }
            }
        }
    }

    .text_content {
        text-align: center;
    }

    .star_rating_content {
        display: flex;
        // justify-content: center;
        justify-content: space-between;
    }

    .bold_font {
        font: bold 0.7rem Source Sans Pro;
    }

    // .normal_font {
    //     font: 300 0.7rem Source Sans Pro;
    // }

    .new_width {
        width: 100%;
    }

    .width_ {
        width: 100%;
    }

    .store_links_first>a>img {
        width: 20%;
        margin: 0 0.5rem;
    }

    .store_links {
        margin-top: 2rem;
    }

    .store_links>a>img {
        width: 30%;
    }
}

@media screen and (max-width:550px) {
    .statmockup_image {
        width: 80%;
    }

    .display_none {
        display: none;
    }

    .display_block {
        display: block;
    }

    .text_hide {
        // display: none;
    }

    .main_background {
        height: 70vh;
    }

    .content_div {
        height: 70vh;

        .text_content {
            // h2 {
            //     font: 900 1.5rem/2.5rem sofiapro;
            //     letter-spacing: 0.5px;
            // }

            .normal_font {
                font: 400 0.8rem/1rem Source Sans Pro;
            }

            .store_btn {
                img {
                    width: 30%;
                }
            }
        }
    }

    .trusted_text {
        font: bold 1.5rem/2rem Source Sans Pro;
    }

    .medium_font {
        font: 600 1rem Source Sans Pro;
    }

    .trusted_text_subtitle {
        font: 400 0.8rem/1rem Source Sans Pro;
    }

    .main_slider {
        width: 100%;
        margin-top: -1rem;
    }

    .slick-dots {
        bottom: -40px !important;
    }

    .slick-prev:before,
    .slick-next:before {
        font-size: 20px !important;
    }

    .slick-next {
        margin-right: 5px !important;
    }

    .slick-prev {
        margin-left: 5px !important;
    }

    .third_div {
        padding: 3rem 0.7rem;
        height: 100%;
    }

    .bullet_image1 {
        height: 40vh;
    }

    .big_font {
        font: bold 1rem Source Sans Pro;
    }

    .normal_font1 {
        font: 600 0.7rem/1rem Source Sans Pro;
    }

    .margin_text {
        margin: 1.5rem 0;
    }

    .normal_font {
        font: 400 0.9rem Source Sans Pro;
    }

    .small_font {
        font: bold 1rem/1.5rem Source Sans Pro;
        letter-spacing: 0.5px;
    }

    .title_font {
        font: bold 1.5rem Source Sans Pro;
    }

    .subtitle_font {
        font: 400 0.9rem Source Sans Pro;
    }

    .back_design {
        padding: 3rem 0.7rem;
    }

    .status_div {
        padding: 0 0.7rem;
    }

    .pricing_div {
        padding: 2rem 1.5rem;
    }

    .prices_list {
        padding: 1rem;
    }

    .store_links_first>a>img {
        width: 28%;
        margin: 0 0.2rem;
    }

    .store_links>a>img {
        width: 35%;
    }

    .price_store_links>a>img {
        width: 40%;
        margin: 0 0.4rem;
    }
}