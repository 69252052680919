/* LoginOption styling=========******************** */
.country_select_input::placeholder {
  color: #1f1f1f !important;
}
.user_img {
  width: 100%;
}
.annual_card {
  background: #fff;
  padding: 15px 11px;
  box-shadow: 0px 5px 15px #0000000d;
  border-radius: 8px;
  border: 1px solid lightgray;
}

.best_value_card {
  background: #f0e4b2;
  padding: 0.2rem 0.4rem;
  border-radius: 5px;
}

.main_img_div {
  position: relative;
}

.auth_img {
  width: 100%;
  height: 100vh;
}

.login_opt {
  background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.816),
      rgba(255, 0, 0, 0) 70.71%
    ),
    url("https://d1uxchv5kl1noj.cloudfront.net/squat.png") no-repeat center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: auto;
  padding: 2rem 0;
}

.login_opt_content {
  width: 50%;
  text-align: center;
  background: #ffffffdf;
  padding: 3rem 2rem;
  box-shadow: 0px 0px 30px #a6a6a633;
  border-radius: 10px;
}

.logo_img {
  width: 30%;
  margin: 0 0 1.5rem 0;
}

.extra_style {
  background: transparent;
  color: #222;
  border: 2px solid #222;
}

/* Register styling=========********************---------------=========*/
.register_img_div {
  display: flex;
  background: linear-gradient(to top, #0d0d0de0, #0d0d0d40 80%),
    url("https://d1uxchv5kl1noj.cloudfront.net/bulletimage1.jpeg") no-repeat
      center;
  background-size: cover;
  height: 100vh;
  overflow: auto;
  padding: 2rem 0;
}

.normal_subs_div {
  background: linear-gradient(to bottom, #1f1f1f34, #1f1f1f),
    url("../assets/bulletimage2.png") center, no-repeat !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover !important;
  height: 100vh;
  overflow: auto;
  padding: 2rem 0;
}

.login_img_div {
  display: flex;
  /* background: url('../../assets//situps.png') no-repeat center; */
  background: linear-gradient(to top, #0d0d0de9, #0d0d0d40 80%),
    url("https://d1uxchv5kl1noj.cloudfront.net/situps.png") no-repeat top;
  background-size: cover;
  height: 100vh;
  overflow: auto;
  padding: 2rem 0;
}

.forgot_img_div {
  display: flex;
  background: linear-gradient(to top, #0d0d0de9, #0d0d0d40 80%),
    url("https://d1uxchv5kl1noj.cloudfront.net/bulletimage2.png") no-repeat
      center center;
  background-size: cover;
  height: 100vh;
  overflow: auto;
  padding: 2rem 0;
}

.verify_img_div {
  display: flex;
  background: linear-gradient(to top, #0d0d0de9, #0d0d0d40 80%),
    url("https://d1uxchv5kl1noj.cloudfront.net/sit.png") no-repeat center;
  background-size: cover;
  height: 100vh;
  overflow: auto;
  padding: 2rem 0;
}

.login_opt_content2 {
  margin: auto;
  width: 45%;
  text-align: center;
  background: #ffffffdf;
  box-shadow: 0px 0px 30px #a6a6a633;
  border-radius: 10px;
  padding: 3rem 2rem;
}

.ant-upload-list-picture-card .ant-upload-list-item-error {
  border-color: #ae841e;
}

.ant-upload.ant-upload-select-picture-card {
  border: none !important;
  background: transparent !important;
}

.ant-upload.ant-upload-select-picture-card > .ant-upload {
  border-radius: 50% !important;
}

.ant-upload-list-picture-card .ant-upload-list-item {
  border-radius: 50% !important;
  /* object-fit: cover !important; */
}

.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  border-radius: 50% !important;
  /* object-fit: cover !important; */
}

.ant-upload-list-item-actions .anticon-eye {
  display: none;
}

.ant-tooltip-placement-top {
  display: none !important;
}

.ant-upload-list-picture-card .ant-upload-list-item-info {
  border-radius: 50% !important;
  object-fit: cover !important;
}

.ant-upload-list-picture-card .ant-upload-list-item-info::before {
  background: transparent !important;
}

.anticon-delete {
  color: #ae841e !important;
  font-weight: bolder !important;
}

.ant-upload-list-item-actions .anticon-eye {
  display: none;
}

.ant-tooltip-placement-top {
  display: none !important;
}

.ant-input-affix-wrapper {
  height: 50px;
  border-radius: 5px;
  border: none;
}

.ant-input-affix-wrapper:focus {
  border-color: transparent !important;
}

svg {
  font-size: 1.4rem;
  /* color: #AE841E; */
}

/* Login form styling=========********************--------------*/
.login-form {
  margin: auto;
  text-align: left;
  width: 70%;
}

.inputText {
  width: 100%;
  outline: none;
  border: none;
  height: 50px;
  padding: 4px 11px;
  box-shadow: 0px 5px 15px #0000000d;
  border-radius: 5px;
}

.inputText2 {
  width: 100%;
  outline: none;
  border: 0.5px solid #1f1f1f;
  height: 50px;
  padding: 4px 11px;
  border-radius: 5px;
  background: transparent;
}

.inputText2:hover {
  border: 0.5px solid #1f1f1f;
}

.inputText2:focus {
  border: 0.5px solid #1f1f1f;
}

.PhoneInput {
  background: #fff;
  height: 50px;
  padding: 4px 11px;
  border-radius: 5px;
}

.PhoneInputInput {
  height: 50px;
  border: none;
  border-radius: 5px;
  padding: 4px 11px;
  outline: none;
}

.forgot_text a {
  color: #222;
  font-weight: bold;
  text-decoration: none;
}

.extra_btn_style3 {
  background-color: #ffffff;
  color: #222;
  box-shadow: 0px 5px 15px #0000000d;
  border-radius: 5px;
}

/* forgot form styling=========********************------&&&&&&&&&&&&&&--------*/
.forgot_width {
  /* width: 50%; */
}

/* verification form styling=========********************------&&&&&&&&&&&&&&--------*/
.vi__character {
  height: 60px !important;
  border: none !important;
  box-shadow: 0px 5px 15px #0000000d;
  border-radius: 5px;
  line-height: 60px !important;
  background: #fff !important;
  color: #ae841e !important;
  margin: 0 5px;
}

.vi__character--selected {
  outline: 2px solid #ae841e;
}

.so_snall_font {
  font: bold 0.7rem Source Sans Pro;
}

@media screen and (min-width: 1700px) {
  .login_opt_content {
    width: 50%;
    padding: 4rem 3rem;
  }

  .logo_img {
    width: 30%;
    margin: 0 0 2rem 0;
  }

  .inputText {
    height: 60px;
  }

  svg {
    font-size: 1.8rem;
  }

  .so_snall_font {
    font: bold 1.2rem Source Sans Pro;
  }

  .vi__character {
    height: 80px !important;
  }

  :where(.vi__container) {
    gap: 10px !important;
    height: 80px !important;
    width: 500px !important;
  }
}

@media screen and (max-width: 991px) {
  .login_opt_content2 {
    width: 60%;
  }
}

@media screen and (max-width: 768px) {
  .extra_padding {
    padding: 1rem 2rem;
  }

  .login-form {
    width: 100%;
  }

  .vi__character {
    width: 50px;
    height: 50px !important;
    gap: 0px !important;
  }

  .login_opt_content2 {
    margin: auto;
    width: 90%;
    padding: 2.5rem;
  }

  .login_opt_content {
    width: 60%;
  }
}

@media screen and (max-width: 550px) {
  .logo_img {
    width: 50%;
    margin: 0 0 1rem 0;
  }
  :where(.vi__wrapper) {
    /* position: relative; */
    width: 100% !important;
  }

  :where(.vi__container) {
    /* display: flex; */
    /* gap: 8px !important; */
    width: 100% !important;
  }

  .auth_img {
    height: 100%;
  }

  .login_opt_content {
    top: 55%;
    width: 90%;
    padding: 1.5rem 1.5rem;
    margin: 1rem 0;
    border-radius: 5px;
  }

  .login_opt_content2 {
    padding: 1.5rem;
    border-radius: 5px;
  }

  .user_img {
    width: 90%;
  }

  svg {
    font-size: 1.4rem;
  }
  .extra_padding {
    padding: 1rem 1rem;
  }
}
