@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&family=Raleway:wght@400;500;600;700;900&family=Source+Sans+Pro:wght@300;400;600;700;900&display=swap");

* {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
  font-family: "Source Sans Pro", sans-serif;
  scroll-behavior: smooth;
}
/* .dropdown-menu[data-bs-popper] {
  top: 160% !important;
  left: -132px !important;
  padding: 0.2rem 1rem;
} */
.ant-modal-header {
  border-bottom: none !important;
}

.ant-tooltip-inner {
  display: none !important;
}

.img_div_fb {
  background: #000;
  border-radius: 10px;
  padding: 2rem;
  margin: 2rem 0;
}

.dash_program_image{
  width: 100%;
  height: 320px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* .ant-btn[disabled], .ant-btn[disabled]:active, .ant-btn[disabled]:focus, .ant-btn[disabled]:hover{
  background: #1f1f1f;
  color: #fff;
} */

/*++++++++++++++++++++++++++++++++++++++++ new version classes========================================= */
.btn-small {
  height: 35px !important;
  background: gainsboro !important;
  color: #000 !important;
}

.workout_data {
  background: rgb(235, 235, 235);
  text-align: center;
  padding: 4rem 2rem;
  border-radius: 10px;
  margin-top: 1rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  cursor: pointer;
}

.black_div {
  background: #000;
  border-radius: 10px;
  padding: 1rem 0.8rem;
  height: 70px;
}

.img-dv {
  height: 300px;
  width: 100%;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
  position: relative;
  border-radius: 5px;
}
.img-dv-two {
  min-width: 350px;
  width: 400px;
  cursor: pointer;
}
.inner-dv {
  background: #000000a8;
  padding: 0.5rem 0.5rem 0.5rem 1.5rem;
  position: absolute;
  width: 100%;
  border-radius: 0px 0px 5px 5px;
  bottom: 0;
}

.inner-dv-two {
  background: #b8b8b858 !important;
}

.exce-dv {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  cursor: pointer;
}

.video-dv {
  display: flex;
  width: 100%;
  overflow: auto;
  padding-bottom: 0.5rem;
}

.video-dv > video {
  /* margin-right: 1rem; */
  object-fit: cover;
  border-radius: 10px;
  min-width: 450px;
  width: 100%;
  height: 300px;
}

.main-dv {
  width: 100%;
  display: flex;
  overflow: auto;
}

.date-dv {
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(215, 215, 215, 0.907);
  margin-bottom: 1.5rem;
}

.journal-card-dv {
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
    rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
  padding: 1rem;
  margin: 1.5rem 0;
  border-radius: 5px;
}

.sub-title {
  font-size: 1rem;
  font-weight: 600;
}

.sixth_section {
  /* height: 100vh; */
  overflow: auto;
}

.sixth_section::-webkit-scrollbar{
  display: none;
}

.steps_container {
  transition: all 1s ease !important;
}
