.welcome_fist{
    height: 100vh;
    background: #FDFBF8;
    display: flex;
    justify-content: center;
    align-items: center;
}
.big_fist{
    position: relative;
}
.bullet_logo{
    position: absolute;
}
.big_fist_img{
    width: 50%;
}
.welcome_logo_width{
    width: 25%;
}
@media screen and (min-width:1700px) {
    .big_fist_img{
        width: 100%;
    }
}
@media screen and (max-width:550px) {
    .big_fist_img{
        width: 100%;
    }
    .welcome_logo_width{
        width: 50%;
    }
}