/* **********Card Info design**************** */
.card_brand_div{
  background: #ae841e;
  padding: 0.5rem;
  border-radius: 7px;
}
.card_brand_div > span{
  font-style: italic;
  letter-spacing: 1px;
}


/* common classes==========================----------------- */
.width_manage_ {
  width: 50%;
}

.css-jfeqr5 {
  z-index: 55555 !important;
}

.css-nvf14r-ToastContainer {
  z-index: 55555 !important;
}

.ant-modal-content {
  margin-top: 5rem !important;
}

.tabs_margin {
  margin: 4rem 0 0 0;
}

.extra_margin {
  margin: 4rem 0 0 0;
}

.very_extra_margin {
  margin: 7rem 0 0 0;
}

.extra_padding {
  padding-top: 5rem;
}

.extra_padding_top {
  padding-top: 1rem;
}

.fluid_content {
  width: 95%;
  margin: auto;
}

.locked_btn {
  position: relative;
  font: 600 1rem/1.5rem Source Sans Pro;
  border: none;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.locked_btn:hover {
  background: #e6d8a1;
  color: #ae841e;
}

.locked_btn:focus {
  background: #e6d8a1;
  color: #ae841e;
}

.lock_btn {
  position: absolute;
  bottom: 0%;
  right: 1.5%;
}

.common_btn {
  background: #ae841e;
  color: #fff;
  border: none;
  padding: 0 4rem;
  height: 50px;
  font: 600 1rem/1.5rem Source Sans Pro;
  border-radius: 5px;
}

.common_btn2 {
  background: #fff;
  color: #000;
  border: none;
  padding: 0 4rem;
  height: 50px;
  font: 600 1rem/1.5rem Source Sans Pro;
  border-radius: 5px;
  box-shadow: 0px 5px 10px #2222221a;
}

.common_btn_color {
  background: #1f1f1f;
}

.add_program_btn {
  background: #1f1f1f;
}

.common_btn:hover {
  background: #ae831ed6;
  color: #ffffff;
  border-color: #ae831ed6;
}

.common_btn:focus {
  background: #ae831ed6;
  color: #ffffff;
  border-color: #ae831ed6;
}

.common_color {
  color: #fff;
}

.common_color2 {
  color: #222222;
}

.common_color3 {
  color: #ae831ed6;
}

.common_background {
  background: hsl(48, 100%, 98%);
  padding: 7rem 0 3rem 0;
}

.common_background2 {
  background: #1f1f1f !important;
}

.price_background {
  background: linear-gradient(to bottom, #1f1f1f3a, #1f1f1fd0),
    url("../assets/bulletimage2.png") center, no-repeat !important;
  background-size: cover !important;
}

.academy_background {
  background: linear-gradient(to bottom, #1f1f1f3a, #1f1f1fd0),
    url("../assets/bulletimage3.png") center, no-repeat !important;
  background-size: cover !important;
}

.center_content {
  width: 50%;
  margin: auto;
}

.extra_btn_style2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.inputText:focus {
  border: 1.5px solid #ae841e;
}

/* question styling+++++++++++++++++++++++++++++++++++++ */
.ant-progress-text {
  display: none;
}

.ant-progress-bg,
.ant-progress-success-bg {
  background-color: #ae841e !important;
}

.ant-progress-status-success .ant-progress-bg {
  background-color: #ae841e;
}

.ant-progress-inner {
  background-color: #0000001d !important;
  height: 50px !important;
  width: 50px !important;
}

/* .progress-dv{} */

.ant-progress-show-info .ant-progress-outer {
  padding-right: calc(0em + 4px) !important;
}

.programBarBtn {
  background: #ae841e;
  border-radius: 5px;
  color: #fff;
  font-style: italic;
  font-weight: 600;
}

.programBarBtn:hover {
  background: #ae841e;
  color: #fff;
}

.programBarBtn:focus {
  background: #ae841e;
  color: #fff;
}

.css-tj5bde-Svg {
  fill: #ae841e !important;
}

.css-1u9des2-indicatorSeparator {
  display: none !important;
}

.ant-select-single .ant-select-selector {
  height: 50px !important;
  outline: none !important;
  box-shadow: 0px 5px 15px #0000000d !important;
  border: none !important;
  border-radius: 5px !important;
  padding: 8px 11px !important;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #fffdf5;
  padding: 0.5rem;
  color: white;
  text-align: center;
}

/* Subscriptions styling+++++++++++++++++++++++++++++++++++++ */
.active_subs_div {
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  padding: 1.5rem;
  /* text-align: center; */
  border-radius: 10px;
}

.trial_card_div {
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  padding: 2rem;
  /* text-align: center; */
  border-radius: 10px;
}

.StripeElement {
  display: block;
  /* margin: 10px 0 20px 0; */
  /* max-width: 100%; */
  padding: 1rem 11px;
  font-size: 1em;
  /* font-family: "Source Code Pro", monospace; */
  /* box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px; */
  border: 1px solid #1f1f1f;
  outline: 0;
  border-radius: 4px;
  background: white;
  /* height: 50px !important; */
}

input::placeholder {
  color: #aab7c4;
}

.price_card {
  box-shadow: 0px 10px 40px #8080801a;
  border-radius: 20px !important;
}

.price_content {
  background: linear-gradient(#f0e4b2c7, #f0e4b2ef),
    url("../assets/fist_colour.png") center no-repeat;
  background-size: cover;
  box-shadow: 0px 5px 15px #95959526;
  border-radius: 20px;
  padding: 1rem;
  /* height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between; */
}

.price_content2 {
  background: url("../assets/bigfist.png") center no-repeat;
  background-size: cover;
  box-shadow: 0px 5px 15px #95959526;
  border-radius: 20px;
  padding: 1rem;
}

.academy_back {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  padding: 1rem 0.8rem;
  box-shadow: 0px 5px 15px #7a7a7a26;
  border-radius: 5px;
}

.ant-radio-inner {
  border-color: #ae841e !important;
  background-color: #f0e4b2;
}

.ant-radio-checked .ant-radio-inner:after {
  background-color: #ae841e;
}

.btn_font {
  font: 300 1.2rem Source Sans Pro;
}

.btn_font2 {
  font: 300 0.7rem Source Sans Pro;
}

/* dashboard Stats styling-------------------*****************%%%%%%%%%%%%%%% */
.new_small_font {
  font: bold 1rem Source Sans Pro;
}

.new_normal_font {
  font: normal 0.8rem Source Sans Pro;
}

.cards {
  position: relative;
  border-radius: 10px;
  cursor: pointer;
}

.lock_image {
  background: rgba(212, 212, 212, 0.87);
  position: absolute;
  z-index: 1;
  top: 2%;
  right: 2%;
  padding: 0.5rem;
  border-radius: 50%;
}

.img_content {
  padding: 0.8rem 0 0.7rem 0.7rem;
  background-color: #1f1f1f;
  width: 100%;
  min-height: 60px;
  border-radius: 0 0 8px 8px;
}

.card_font {
  font: 300 0.9rem Source Sans Pro;
  color: #fff;
}

.progress_bar {
  margin: auto;
  width: 80%;
  box-shadow: 0px 10px 40px #ae841e1a;
  border-radius: 10px;
  background: #fff;
  padding: 1.5rem 3rem;
}

.all_stats_bar {
  margin: auto;
  width: 80%;
}

.bjj_info {
  box-shadow: 0px 10px 40px #ae841e1a;
  border-radius: 10px;
  background: #fff;
  padding: 0.5rem;
}

.bjj_info_btn {
  background: rgb(69, 105, 144);
  font: italic bold 1rem Source Sans Pro;
  padding: 0.2rem 1rem;
  width: 50%;
  color: #fff;
  border-radius: 5px;
}

.points_btn {
  border: 0.5px solid #e6d8a1;
  padding: 0.5px 0;
  border-radius: 50px;
  margin-left: 0.5rem;
}

.svg_star {
  background: orange;
  color: #fff;
  border-radius: 50%;
  height: 14px;
  width: 14px;
  padding: 1px;
  margin-left: 1rem;
}

.monthly_weekly {
  box-shadow: 0px 10px 10px #0d0d0d1a;
  border-radius: 5px;
}

.mobilty {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: #30ddfa;
}

.mobilty_div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0rem 0 1.5rem 0;
}

.program_view {
  box-shadow: 0px 5px 20px #6161611a;
  border-radius: 10px;
}

.ant-modal-footer {
  border-top: 0;
}

.slide-but {
  border-radius: 50px !important;
  background: #000 !important;
  color: #fff !important;
  border: none !important;
}

.my-overlay-class {
  background: transparent !important;
}

.my-caret-class {
  /* background:#ae841e !important; */
  background: transparent !important;
  /* padding: 2rem !important; */
  /* border-radius: 50px; */
}

.dashboard_card {
  /* minHeight: 250, height: 700, backgroundPosition: "top", backgroundSize: "cover", backgroundRepeat: "no-repeat"? */
  height: 300px;
  background-size: cover !important;
  background-position: top !important;
  width: 100% !important;
  border-radius: 8px 8px 0 0;
}

/* Training Video styling -------------------*****************%%%%%%%%%%%%%%% */
.btn_div {
  background: #fffcee;
  border: 2px solid #f0e4b2;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  padding: 0;
}

.btn_div:hover {
  border-color: #f0e4b2;
  background: #fffcee;
}

.btn_one {
  font-weight: bold;
  color: #222222;
  padding: 0.1rem 2rem;
  text-decoration: none;
  border: 3px solid #f0e4b2;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  border-top: none;
  border-bottom: none;
  border-left: none;
}

.btn_one:hover {
  color: #ae841e;
}

.border_none {
  border: none;
}

.btn_two:hover {
  color: #ae841e;
}

.video_div {
  max-width: 80%;
  margin: auto;
}

.gym_video {
  border-radius: 10px;
  width: 100%;
}

.gym_video[poster] {
  border-radius: 10px;
  width: 100%;
  height: 550px;
  object-fit: cover;
}

.foundation_video {
  width: 60%;
  border-radius: 10px;
}

.sec_gym_video {
  height: 300px;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
}

/* video[poster]{
  height:300px;
  width:100%;
  object-fit: cover;
  } */

/* community styling -------------------*****************%%%%%%%%%%%%%%% */
/* .main_feature_card {
  background-color: #F0E4B2;
} */

.featured_card {
  /* background-color: red; */
  box-shadow: 0px 10px 30px #22222214;
  /* padding: 2rem 0; */
  /* margin: 0 0 1rem 0; */
}

.profile_card {
  box-shadow: 0px 10px 30px #22222214;
  border-radius: 10px;
  /* text-align: center; */
  border: none;
  /* background: rgba(0, 0, 0, 0.155); */
}

.sec_profile_width {
  width: 40px;
  border-radius: 50%;
}

.profile_img_width {
  width: 50px;
  border-radius: 50%;
}

.text_input {
  background: #f8f9fa;
  color: #222222;
  outline: none;
  resize: none;
  border-radius: 5px;
  border: none;
}

.upload_input {
  background: #f8f9fa;
  color: #222222;
  padding: 0.5rem 0;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
}

.filter {
  background: #f5f5f5;
}

.like {
  box-shadow: 0px 5px 10px #0000000d;
  padding: 0.5rem;
  border-radius: 50%;
  cursor: pointer;
}

.like_num {
  box-shadow: 0px 5px 10px #0000000d;
  border-radius: 10px;
  padding: 0.5rem 1rem;
}

/* Blogs styling -------------------*****************%%%%%%%%%%%%%%% */
.main_blog_div {
  height: 100vh;
  overflow: auto;
}

.blogs_back {
  background: #f9f9f9;
  padding: 2rem;
  border-radius: 5px;
  /* width: 90%;
  margin: auto; */
}

.read_more_btn {
  text-decoration: none;
  color: #ae841e;
  cursor: pointer;
}

.read_more_btn:hover {
  color: #ae831ed6;
}

.icon_flex {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.blog_input {
  box-shadow: 0px 5px 15px #95959526;
  border-radius: 5px;
  border: none;
  resize: none;
}

.ant-input-focused,
.ant-input:focus {
  box-shadow: 0px 5px 15px #95959526;
}

.carousel .carousel-status {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  font-size: 15px !important;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.9);
  color: #000 !important;
}

.carousel img {
  width: 100%;
  display: inline-block;
  pointer-events: none;
  object-fit: contain;
  height: 100%;
}

.carousel .slide {
  height: 400px !important;
}

.carousel .thumbs {
  text-align: center !important;
}

.ant-btn-primary {
  border-color: #ae841e;
  background: #ae841e;
}

.ant-btn-primary:hover {
  /* border-color: #0000005a; */
  border-color: #ae841e;
  background: #ae841e;
}

.community_post_img {
  object-fit: cover;
  width: 100%;
  height: 300px;
}

/* podcast styling-----------------================&&&&&&&&&&&&&&&&&&& */
.podcast_back {
  background: #fbfbfb;
  padding: 6rem 0 3rem 0;
}

.podcast_back2 {
  background: #1f1f1f;
  padding: 3rem 0;
}

.podcast_img {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to top, #0d0d0de9, #0d0d0d40 100%),
    url("../assets/wrestling.png") no-repeat top;
  background-size: cover;
}

.podcast_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.podcast_btn {
  display: flex;
}

.all_podcast_btn {
  width: 100%;
  box-shadow: 0px 5px 10px #2222221a;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 2rem 0;
  margin: 0 1rem 0 0;
}

.date_font {
  color: #2d2d2d69;
  font: 400 1.5rem/1.5rem Source Sans Pro;
}

.audio_player {
  box-shadow: 0px 5px 15px #0000000d;
  border-radius: 5px;
  /* height: 110px; */
}

.podcast_desc {
  background: #fffcee93;
  padding: 3rem 0;
}

.custom-audio-player {
  width: 100%;
  border-radius: 5px;
  background: #0000000d;
}

.audio_controls > button {
  border: none;
  color: #222222 !important;
  background: transparent;
}

/***************** Audio player styling********** */
input[type="range"] {
  height: 37px;
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
}
input[type="range"]:focus {
  outline: none;
}
input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 2px #000000;
  background: #222222;
  border-radius: 50px;
  border: 0px solid #222222;
}
input[type="range"]::-webkit-slider-thumb {
  box-shadow: 1px 1px 7px #000000;
  border: 0px solid #000000;
  height: 30px;
  width: 15px;
  border-radius: 50px;
  background: #ffffff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -10px;
}
input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #222222;
}
input[type="range"]::-moz-range-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  box-shadow: 0px 0px 2px #000000;
  background: #222222;
  border-radius: 50px;
  border: 0px solid #222222;
}
input[type="range"]::-moz-range-thumb {
  box-shadow: 1px 1px 7px #000000;
  border: 0px solid #000000;
  height: 30px;
  width: 15px;
  border-radius: 50px;
  background: #ffffff;
  cursor: pointer;
}
input[type="range"]::-ms-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type="range"]::-ms-fill-lower {
  background: #222222;
  border: 0px solid #222222;
  border-radius: 100px;
  box-shadow: 0px 0px 2px #000000;
}
input[type="range"]::-ms-fill-upper {
  background: #222222;
  border: 0px solid #222222;
  border-radius: 100px;
  box-shadow: 0px 0px 2px #000000;
}
input[type="range"]::-ms-thumb {
  margin-top: 1px;
  box-shadow: 1px 1px 7px #000000;
  border: 0px solid #000000;
  height: 30px;
  width: 15px;
  border-radius: 50px;
  background: #ffffff;
  cursor: pointer;
}
input[type="range"]:focus::-ms-fill-lower {
  background: #222222;
}
input[type="range"]:focus::-ms-fill-upper {
  background: #222222;
}

/* .wavesurfer-container {
  margin: 1rem 0;
}
.wavesurfer-controls {
  display: flex;
  margin-top: 1.5rem;
}
.wavesurfer-controls button {
  font-size: 1.5rem;
  color: white;
  margin: 0 0.5rem;
}
.wavesurfer-controls button:nth-child(2),
.wavesurfer-controls button:nth-child(3) {
  background-color: #f90;
  background: linear-gradient(210deg, #f90, rgb(227, 180, 114));
  border-radius: 2rem;
  padding: 0.8rem;
  background: #191b28;
  box-shadow: inset 11px 11px 22px #0a0b10, inset -11px -11px 22px #282b40;
} */

/* edit profile styling-----------------================&&&&&&&&&&&&&&&&&&& */
.edit_icon {
  position: absolute;
  top: -10%;
  left: 52%;
  width: 10%;
}

.profile_pic_style {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  /* width: 20%; */
}

/* journal styling-----------------================&&&&&&&&&&&&&&&&&&& */
.calender_style {
  box-shadow: 0px 10px 50px #3131311a;
  margin: 2rem 0;
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
  width: 80px;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today .ant-picker-calendar-date-value,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date .ant-picker-calendar-date-value,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today .ant-picker-calendar-date-value,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date .ant-picker-calendar-date-value {
  background: #e6d8a1 !important;
  color: #fff !important;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
  background: #fff !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  /* background: #F0E4B2; */
}

.ant-picker-calendar-header {
  display: block !important;
}

.ant-badge-status-dot {
  margin: 0 1px !important;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
  margin: 0 !important;
  padding: 0 !important;
  border-top: none !important;
  text-align: center;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
  height: 30px !important;
  text-align: center !important;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
  padding: 1rem 0 !important;
  line-height: normal !important;
  text-align: center !important;
  background: #f0e4b2e8;
}

.ant-picker-calendar-mode-switch {
  display: none !important;
}

.bjj_btn {
  border: none;
  border-radius: 10px;
  background: #456990;
  color: #fff;
  height: 40px;
}

.bjj_btn_strength {
  background-color: #ef767a;
}

.bjj_btn_mobility {
  background-color: #49beaa;
}

.bjj_btn:hover {
  background: #456990;
  color: #fff;
}

.bjj_btn:focus {
  background: #456990;
  color: #fff;
}

/* FAQ styling-----------------================&&&&&&&&&&&&&&&&&&& */
.collapse_style {
  border: none;
  background: transparent;
}

.panel_style {
  border: none !important;
  /* box-shadow: 0px 3px 10px #B7B7B726; */
  border-radius: 5px;
  background: #f6f6f6;
}

.ant-collapse-content {
  border: none;
}

/* Shop styling-----------------================&&&&&&&&&&&&&&&&&&& */
.shop_cart_text {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.confirm_tag {
  background: #222;
  color: #fff !important;
  padding: 0.2rem 0.5rem;
  border-radius: 5px;
}

.download_tag {
  /* background: #222; */
  cursor: pointer;
  color: #ae841e !important;
  padding: 0.2rem 0.5rem;
  border-radius: 5px;
  text-decoration: underline;
}

.icon_search {
  background: #ae841e;
  color: #fff;
  /* height: 50px; */
  padding: 1rem 1.5rem;
}

.product_card {
  box-shadow: 0px 20px 40px #6161611a;
  border-radius: 10px;
  /* height: 450px; */
  /* padding: -12px !important; */
}

.cut_text {
  text-decoration: line-through;
  font: 400 1rem Source Sans Pro;
  color: #ae841e;
  padding-left: 0.5rem;
}

.ant-card-body {
  padding: 12px !important;
}

/* Product details styling-----------------================&&&&&&&&&&&&&&&&&&& */

.carousel .thumb {
  height: 100px !important;
}

.product_detail {
  /* display: flex;
  flex-direction: column;
  justify-content: space-between; */
}

.ant-tabs-top>.ant-tabs-nav:before {
  border-bottom: none !important;
}

.ant-tabs-tab {
  border-bottom: 2px solid #ae831e5d;
  width: 400px;
  display: flex;
  justify-content: center;
  font: bold 1.2rem Source Sans Pro;
}

.ant-tabs-tab+.ant-tabs-tab {
  margin: 0 0 0 10px;
}

.ant-tabs-nav-list {
  width: 70%;
  margin: auto;
  display: flex;
  justify-content: space-between;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ae841e;
}

.ant-tabs-tab:hover {
  color: #000;
  /* border-bottom: 2px solid #ae841e; */
}

.ant-tabs-ink-bar {
  background: #ae841e;
}

.ant-tabs-top>.ant-tabs-nav {
  margin: 0 !important;
}

/* .size_active_btn:focus {
  background: #AE841E;
  color: #fff;
} */

/* shopping cart styling-----------------================&&&&&&&&&&&&&&&&&&& */
.scroll_carousel {
  padding: 0 0 2.5rem 0;
  width: 100%;
  overflow: auto;
  display: block;
}

.cart_img_height {
  height: 100px;
  width: 110px;
  object-fit: cover;
}

.scroll_none::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar {
  width: 0.4em;
  height: 0.5em;
}

::-webkit-scrollbar-track {
  background-color: #201e1938;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #1f1f1fa9;
  border-radius: 10px;
}

.shop_cart {
  padding: 0.8rem;
  box-shadow: 0px 5px 15px #0000000d;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
}

.shop_cart_btn>button {
  height: 40px;
  border: 2px solid #f0e4b2;
}

.shop_cart_btn>button:hover {
  border: 2px solid #f0e4b2;
  color: #000;
}

.shop_cart_btn>button:focus {
  border: 2px solid #f0e4b2;
  color: #000;
}

.shop_cart_btn>button:first-child {
  background: #f0e4b2 !important;
}

.shop_cart_btn>button:last-child {
  background: #f0e4b2 !important;
}

.del_btn {
  box-shadow: 0px 3px 15px #8d8d8d1a;
  border: none;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  color: #fe4343;
  padding: 0%;
}

.del_btn:hover {
  color: #fe4343;
}

.del_btn:focus {
  color: #fe4343;
}

.sub_total_div {
  box-shadow: 0px 5px 15px #0000000d;
  border-radius: 5px;
  padding: 1rem;
  background: #fff;
}

.address_card {
  box-shadow: 0px 5px 15px #7a7a7a26;
  border-radius: 10px;
  padding: 0.8rem;
}

.select_input {
  /* width: 100%; */
  outline: none !important;
  /* border: none !important; */
  /* height: 50px; */
  /* padding: 4px 11px; */
  box-shadow: 0px 5px 15px #0000000d;
  border-radius: 5px;
}

.workout_img {
  position: relative;
  width: 100%;
  cursor: pointer;
}

.complete_workout {
  position: absolute;
  top: 3%;
  right: 2%;
  background: #e6d8a1;
  padding: 0.5rem 1rem;
  border-radius: 5px;
}

.workout_text {
  width: 100%;
  padding: 1rem;
  bottom: 0;
  position: absolute;
  background: #ae831ede;
  border-radius: 10px;
}

.card_img_width {
  object-fit: cover;
  width: 100%;
  border-radius: 10px;
  min-height: 300px;
  height: 300px;
}

.video_play_btn {
  background: #f0e4b2;
  width: 100%;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
}

.video_play_btn:hover {
  background: #e6d8a1;
}

.video_play_btn:focus {
  background: #e6d8a1;
}

.video_thumbnail {
  position: relative;
}

.rs form {
  width: 100% !important;
  min-width: 100% !important;
}

.star_manage {
  width: 13%;
}

.css-1kbzcf1-control {
  box-shadow: none !important;
}

.css-1kbzcf1-control:hover {
  border: 1px solid #000 !important;
}

/* responsivness-----------------================&&&&&&&&&&&&&&&&&&& */
@media screen and (min-width: 1700px) {
  .dash_program_image{
    height: 450px;
  }
  .star_manage {
    width: 10%;
  }

  .img_content {
    min-height: 80px;
  }

  .new_small_font {
    font: bold 1.3rem Source Sans Pro;
  }

  .new_normal_font {
    font: normal 1.2rem Source Sans Pro;
  }

  .StripeElement {
    padding: 1.2rem 11px;
    height: 60px;
  }

  .tabs_margin {
    margin: 5.5rem 0 0 0;
  }

  .extra_margin {
    margin: 7rem 0 0 0;
  }

  .very_extra_margin {
    margin: 9rem 0 0 0;
  }

  .extra_padding {
    padding-top: 9rem !important;
  }

  .image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: calc(70vh - 200px) !important;
  }

  .image-gallery-thumbnails .image-gallery-thumbnails-container {
    width: 65%;
  }

  .common_btn {
    padding: 0 6rem;
    height: 60px !important;
  }

  .common_btn2 {
    padding: 0 6rem;
    height: 60px !important;
  }

  .ant-select-single .ant-select-selector {
    height: 60px !important;
  }

  .common_background {
    padding: 10rem 0 10rem 0;
  }

  .ant-input {
    font-size: 1.2rem !important;
  }

  .ant-input::placeholder {
    font-size: 1.2rem;
  }

  .ant-select-selector {
    font-size: 1.2rem;
  }

  .ant-picker-input>input:placeholder-shown {
    font-size: 1.2rem;
  }

  .price_content {
    padding: 2rem;
  }

  .upload_input {
    padding: 1rem 0;
    font-size: 1.5rem;
  }

  .sec_profile_width {
    width: 80px;
  }

  .profile_img_width {
    width: 100px;
  }

  .btn_margin {
    margin: 5rem 0 !important;
  }

  .edit_icon {
    top: -20%;
    left: 50%;
  }

  .apple_btn {
    padding: 2.5rem 2.5rem 2.5rem 1.5rem;
  }

  .ant-card-body {
    padding: 30px !important;
  }

  .card_img_width {
    min-height: 400px;
    height: 500px;
  }

  .video_play_btn {
    padding: 1.5rem 0;
  }

  .dashboard_card {
    /* minHeight: 250, height: 700, backgroundPosition: "top", backgroundSize: "cover", backgroundRepeat: "no-repeat"? */
    height: 500px;
    background-position: bottom !important;
  }

  .community_post_img {
    height: 600px;
  }

  .carousel .slide {
    height: 700px !important;
  }

  .product_image {
    height: 400px !important;
  }
}

@media screen and (max-width: 768px) {
  .star_manage {
    width: 10%;
  }

  .center_content {
    width: 90%;
  }

  .video_div {
    max-width: 100%;
  }

  .podcast_content {
    text-align: center;
  }

  .podcast_btn {
    justify-content: center;
  }

  .ant-tabs-tab {
    font: bold 0.8rem Source Sans Pro;
  }

  .width_manage_ {
    width: 100%;
  }

}

@media screen and (max-width: 550px) {
  .dash_program_image{
    height: 300px;
  }

  .extra_padding_top {
    padding-top: 0.3rem;
  }

  .rs form {
    padding: 0 !important;
  }

  .very_extra_margin {
    margin: 5.5rem 0 0 0;
  }

  .common_btn {
    padding: 0 2rem;
    height: 50px;
    font: 600 0.9rem Source Sans Pro;
  }
  .common_btn2 {
    padding: 0 2rem;
    height: 50px;
    font: 600 0.9rem Source Sans Pro;
  }

  .btn_font {
    font: 300 1rem Source Sans Pro;
  }

  .btn_one {
    padding: 0.1rem 1rem;
  }

  .btn_two {
    padding: 0.1rem 1rem;
  }

  .sec_gym_video {
    height: 100%;
  }

  .blogs_back {
    padding: 1rem;
    width: 100%;
  }

  .edit_icon {
    width: 20%;
    left: 53%;
  }

  .profile_pic_style {
    height: 75px;
    width: 75px;
    border-radius: 50%;
  }

  .image-gallery-thumbnails .image-gallery-thumbnails-container {
    width: 100% !important;
  }

  .ant-tabs-nav-list {
    width: 95%;
  }

  .progress_bar {
    width: 100%;
    padding: 1.5rem;
  }

  .ant-progress-show-info .ant-progress-outer {
    padding-right: calc(0.5em);
  }

  .foundation_video {
    width: 100%;
  }

  .shop_cart {
    padding: 0.5rem;
  }

  .card_img_width {
    min-height: 300px;
    height: 300px;
  }

  .community_post_img {
    height: 250px;
  }

  .jYkEFA {
    font-size: 1rem !important;
  }

  .ant-progress-show-info .ant-progress-outer {
    padding-right: calc(0em + 4px) !important;
  }

  .all_stats_bar {
    width: 100%;
  }

  .carousel .slide {
    height: 100% !important;
  }

  .dashboard_card {
    height: 300px;
  }

  .star_manage {
    width: 15%;
  }

  .cart_img_height {
    height: 70px;
    width: 80px;
  }

  .shop_cart_btn>button {
    height: 100%;
  }

  .calender_style .sc-dkrFOg {
    padding: 0.5rem !important;
  }

  /* ****************************New version classes *****************************/
  .video-dv>video {
    min-width: 350px;
  }

  .workout_data {
    padding: 2rem 1rem;
    border-radius: 5px;
  }
}